import { useTranslations } from 'next-intl';

import { ModalDialog } from '../ModalDialog';
import { PrimaryButton, SecondaryButton } from '../buttons';

type LocalizationErrorModalProps = {
  onClose: () => void;
};

export default function LocalizationErrorModal({
  onClose,
}: LocalizationErrorModalProps) {
  const t = useTranslations('search.error-localization');

  return (
    <ModalDialog dataTestId="modal-error-localization" onClose={onClose}>
      <div className="flex flex-col gap-5">
        <h3 className="text-xl font-medium">{t('title')}</h3>
        <p>
          {t.rich('description-1', { strong: (chunks) => <b>{chunks}</b> })}
        </p>
        <p>{t('description-2')}</p>
        <div className="flex justify-end gap-3">
          <SecondaryButton
            className="leading-5 text-[16px] basis-28"
            onClick={() => window.location.reload()}
          >
            {t('refresh')}
          </SecondaryButton>
          <PrimaryButton
            className="leading-5 text-[16px] basis-28"
            onClick={onClose}
          >
            {t('ok')}
          </PrimaryButton>
        </div>
      </div>
    </ModalDialog>
  );
}

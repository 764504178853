import { IconProps } from './types';

export default function LoepIcon(props: IconProps) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8245 13.0696L12.1356 12.3448L12.134 12.3463L12.8245 13.0696ZM15 8H16L16 7.99758L15 8ZM18.7071 17.2929L13.7071 12.2929L12.2929 13.7071L17.2929 18.7071L18.7071 17.2929ZM12.2929 13.7071L17.2929 18.7071L18.7071 17.2929L13.7071 12.2929L12.2929 13.7071ZM7.99531 0C3.57942 0 0 3.57781 0 8H2C2 4.6819 4.68447 2 7.99531 2V0ZM0 8C0 12.4222 3.57942 16 7.99531 16V14C4.68447 14 2 11.3181 2 8H0ZM7.99531 16C10.1366 16 12.0788 15.1639 13.515 13.793L12.134 12.3463C11.0571 13.3743 9.60485 14 7.99531 14V16ZM13.5134 13.7945C15.0449 12.3389 16 10.281 16 8H14C14 9.71101 13.286 11.2514 12.1356 12.3448L13.5134 13.7945ZM16 7.99758C15.9893 3.57774 12.4117 0 7.99531 0V2C11.3057 2 13.992 4.68196 14 8.00242L16 7.99758Z"
        fill="inherit"
      />
    </svg>
  );
}

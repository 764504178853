import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';

import closeIcon from '@/public/icons/closeIcon.svg';
import { ID_MODAL_CONTAINER } from '@/utils/constants';

import clsx from 'clsx';

const MAX_WIDTH = 500;

type ModalDialogProps = {
  className?: string;
  children: ReactNode;
  onClose: () => void;
  dataTestId: string;
  width?: number;
  withoutCloseButton?: boolean;
  closable?: boolean;
};

function ModalDialogContainer({
  className,
  onClose,
  children,
  dataTestId,
  width = MAX_WIDTH,
  withoutCloseButton,
  closable = true,
}: ModalDialogProps) {
  const t = useTranslations('general');

  const handleCloseModal = () => {
    if (!closable) return;
    onClose();
  };

  return (
    <div
      role="dialog"
      tabIndex={-1}
      className="fixed top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center"
      aria-modal="true"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      data-testid={dataTestId}
    >
      <div
        className={clsx(
          'relative bg-white rounded-sm max-w-full max-h-[90%] p-6 border border-grey-70 overflow-y-auto',
          className
        )}
        style={{ width: `${width}px` }}
      >
        {children}

        {!withoutCloseButton && closable && (
          <button
            className="absolute w-4 h-4 top-5 right-5"
            type="button"
            aria-keyshortcuts="Escape"
            aria-label={t('close-modal')}
            onClick={handleCloseModal}
          >
            <Image
              data-testid="close-modal"
              src={closeIcon}
              alt={t('close-modal')}
              aria-hidden="true"
            />
          </button>
        )}
      </div>
      <div
        className="fixed top-0 bottom-0 left-0 right-0 bg-black/[.4] -z-10"
        onClick={handleCloseModal}
      />
    </div>
  );
}

export function ModalDialog(props: ModalDialogProps) {
  const container = document.getElementById(ID_MODAL_CONTAINER)!;

  const downHandler = ({ key }: { key: string }) => {
    if (!props.closable) return;
    const pressingEscape = key === 'Escape';
    if (pressingEscape) {
      props.onClose();
    }
  };

  useEffect(() => {
    const disableScroll = () => {
      const { body } = document;
      body.style.height = '100vh';
      body.style.overflowY = 'hidden';
    };

    const restoreScroll = () => {
      const { body } = document;
      body.style.height = '';
      body.style.overflowY = '';
    };

    window.addEventListener('keydown', downHandler);
    disableScroll();

    return () => {
      restoreScroll();
      window.removeEventListener('keydown', downHandler);
    };
  }, []);

  return ReactDOM.createPortal(<ModalDialogContainer {...props} />, container);
}

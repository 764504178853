import { Theme } from 'react-select';

export const getCustomSelectTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 3,
  borderColor: 'border-grey-70',
  colors: {
    ...theme.colors,
  },
});

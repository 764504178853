import Image from 'next/image';
import { OptionProps, components } from 'react-select';

import { CURRENT_LOCATION_VALUE } from '@/components/search/utils';
import localizationIcon from '@/public/icons/markerAltIcon.svg';

import { CustomOption } from './types';

import clsx from 'clsx';

export const CustomOptionSelect = ({
  isFocused,
  data,
  ...props
}: OptionProps<CustomOption>) => {
  const isCurrentLocationOption = data.value === CURRENT_LOCATION_VALUE;

  return (
    <components.Option
      className={clsx(
        'flex px-2 py-1 hover:bg-almond-99 hover:cursor-pointer',
        isFocused && !isCurrentLocationOption && 'bg-almond-99'
      )}
      data={data}
      isFocused={isFocused}
      {...props}
    >
      <div className={clsx(isCurrentLocationOption && 'flex')}>
        {isCurrentLocationOption && (
          <Image
            className="mr-2"
            src={localizationIcon}
            alt="Localization icon"
            aria-hidden="true"
            width={16}
            height={16}
          />
        )}
        <p className={clsx(isCurrentLocationOption && 'text-ocean-60')}>
          {data.label}
        </p>
        {data.description && (
          <p className="text-sm text-grey-25">{data.description}</p>
        )}
      </div>
    </components.Option>
  );
};

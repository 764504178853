import { useLocale, useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';

import { MapboxPlacesResult } from '@/app/api/mapbox-places/types';
import { useDebounce } from '@/hooks/useDebounce';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';
import { useToastError } from '@/hooks/useToastError';
import { useUserLocalization } from '@/hooks/useUserLocalization';
import {
  SimpleIndustriousLocationSearchDto,
  SimpleLocationSearchDto,
} from '@/lib/locations/dto';
import { fetchAllLocations } from '@/lib/locations/location-api';
import { LatLng, getFormattedAddressByCountry } from '@/models';
import BeesIcon from '@/public/icons/beesIcon.svg';
import PlacesIcon from '@/public/icons/placesIcon.svg';
import {
  MAP_SEARCH_ADDRESS_ZOOM,
  MAP_SEARCH_CURRENT_LOCATION,
} from '@/utils/constants';
import { tryFetchToJson } from '@/utils/http-request';
import { stringifyUrl } from '@/utils/stringify-url';

import { SearchField } from '../SearchField';
import { CustomOption } from '../form/CustomSelect/types';
import { CameraMapOptions } from '../map/types';
import LocalizationErrorModal from './LocalizationErrorModal';
import { SearchType } from './search-type.enum';
import { CURRENT_LOCATION_VALUE, getSearchValueFromQuery } from './utils';

const NB_MAX_LOCATIONS = 5;

type SearchInputProps = {
  latLng?: LatLng;
  className?: string;
  placeholder: string;
  isScrollOnFocus?: boolean;
  rightIndicator?: React.ReactNode;
  leftIndicator?: React.ReactNode;
  controlClassName?: string;
  searchType?: SearchType;
  defaultSearchValue?: string | null;
  onClickResultItem: (value: CameraMapOptions, query: string) => void;
  onClickLocation: (location: SimpleLocationSearchDto) => void;
};

type FetchLocationsProps = {
  searchText: string;
  userLocalization?: LatLng;
  locale: string;
  searchType?: SearchType;
};

type FetchMapboxPlacesTextProps = {
  searchText: string;
  coords?: LatLng;
  locale: string;
};

export default function LocationSearchInput({
  latLng,
  className,
  placeholder,
  isScrollOnFocus,
  rightIndicator,
  leftIndicator,
  controlClassName,
  searchType,
  defaultSearchValue,
  onClickResultItem,
  onClickLocation,
}: SearchInputProps) {
  const t = useTranslations('search');
  const searchParams = useSearchParams();

  const { showToast, hideToast } = useToastError();
  const locale = useLocale();
  const screenWidth = useScreenSize();

  const [searchText, setSearchText] = useState<string>();
  const [searchValue, setSearchValue] = useState(
    createSearchValue(getSearchValueFromQuery(searchParams?.get('query')))
  );
  const [options, setOptions] = useState<
    Array<
      | {
          label: string;
          icon: string;
          options: CustomOption[];
        }
      | {
          label: string;
          value: string;
        }
    >
  >([]);

  const [resultsPlaces, setResultsPlaces] = useState<MapboxPlacesResult[]>([]);
  const [resultsLocations, setResultsLocations] = useState<
    SimpleIndustriousLocationSearchDto[]
  >([]);
  const [showErrorLocalization, setErrorLocalization] = useState(false);
  const [loadingBrowserLocation, setLoadingBrowserLocation] = useState(false);

  const {
    lnglat: userLocalization,
    getDeviceLocalization,
    isDeviceLocalizationDenied,
    isLocalizationFromDevice,
    isLoading: isLoadingUserLocalization,
  } = useUserLocalization();

  const autoFocus = useMemo(
    () => !searchValue && screenWidth >= ScreenSize.LG,
    [screenWidth, searchValue]
  );

  const resetResultsPlaces = () => {
    setResultsPlaces((previousValue) =>
      previousValue.length ? [] : previousValue
    );
  };

  const resetResultsAddress = () => {
    setResultsLocations((previousValue) =>
      previousValue.length ? [] : previousValue
    );
  };

  const onInputChangeSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    const setOptionsAsync = async () => {
      const { mapResultsToOptions } = await import('@/utils/mapbox');

      setOptions(
        (resultsPlaces.length === 0 && resultsLocations.length === 0) ||
          !searchText
          ? [{ label: t('current-location'), value: CURRENT_LOCATION_VALUE }]
          : [
              {
                label: t('all-places-title'),
                icon: PlacesIcon,
                options: [...mapResultsToOptions(resultsPlaces, locale)],
              },
              {
                label: t('locations-title'),
                icon: BeesIcon,
                options: [...mapLocationsToOptions(resultsLocations)],
              },
            ]
      );
    };

    setOptionsAsync();
  }, [resultsPlaces, resultsLocations, searchText, locale]);

  const fetchMapboxPlacesText = useDebounce(
    async ({ searchText, coords, locale }: FetchMapboxPlacesTextProps) => {
      const proximity = coords ? [coords.lng, coords.lat] : 'ip';

      const urlParams = {
        searchText,
        language: locale,
        proximity,
      };
      const endpoint = `/api/mapbox-places`;
      const url = stringifyUrl(endpoint, urlParams);

      await tryFetchToJson<MapboxPlacesResult[]>({
        fetchParams: {
          input: url,
        },
        onSuccess: (response) => {
          hideToast();
          setResultsPlaces(response);
        },
        onFunctionalError: () => {
          showToast();
          resetResultsPlaces();
        },
        onTechnicalError: () => {
          showToast();
          resetResultsPlaces();
        },
      });
    },
    200
  );

  const fetchLocations = useDebounce(
    async ({
      searchText,
      userLocalization,
      locale,
      searchType,
    }: FetchLocationsProps) => {
      const locations = await fetchAllLocations({
        locale,
        limit: NB_MAX_LOCATIONS,
        text: searchText,
        onlyInternal: true,
        nearTo: userLocalization && {
          longitude: userLocalization.lng,
          latitude: userLocalization.lat,
        },
        withSubscriptionProductsOnly: searchType === SearchType.Location,
        withCoworkingDayPassOnly: searchType === SearchType.Coworking,
      });
      setResultsLocations(locations as SimpleIndustriousLocationSearchDto[]);
    },
    200
  );

  useEffect(() => {
    if (!searchText) {
      resetResultsPlaces();
      resetResultsAddress();
    } else {
      fetchMapboxPlacesText({
        searchText,
        coords: latLng ?? userLocalization,
        locale,
      });
      fetchLocations({ searchText, userLocalization, locale, searchType });
    }
  }, [searchText, locale]);

  useEffect(() => {
    if (!loadingBrowserLocation) return;
    setSearchValue(createSearchValue(t('finding-location')));

    if (isLocalizationFromDevice) {
      onClickResultItem(
        {
          center: [userLocalization.lng, userLocalization.lat],
          zoom: MAP_SEARCH_CURRENT_LOCATION,
        },
        CURRENT_LOCATION_VALUE
      );
      setLoadingBrowserLocation(false);
      return;
    }

    if (isDeviceLocalizationDenied && !isLoadingUserLocalization) {
      setErrorLocalization(true);
      return;
    }
  }, [
    loadingBrowserLocation,
    isLocalizationFromDevice,
    isDeviceLocalizationDenied,
    isLoadingUserLocalization,
  ]);

  const handleOnClickAddress = async (feature: MapboxPlacesResult) => {
    const { getPlaceNameArrayFromFeature } = await import('@/utils/mapbox');
    const placeName = getPlaceNameArrayFromFeature(feature, locale);

    resetResultsPlaces();
    resetResultsAddress();
    onClickResultItem(
      {
        center: feature.center as [number, number],
        zoom: MAP_SEARCH_ADDRESS_ZOOM,
        ...(feature.bbox && { bounds: feature.bbox }),
      },
      placeName[0]
    );
    setSearchValue(createSearchValue(placeName[0]));
  };

  useEffect(() => {
    if (searchParams?.get('query')) {
      setSearchValue(
        createSearchValue(getSearchValueFromQuery(searchParams?.get('query')))
      );
    }
  }, [searchParams?.get('query')]);

  useEffect(() => {
    if (searchParams?.get('query') && defaultSearchValue !== null) return;
    setSearchValue(createSearchValue(defaultSearchValue || ''));
  }, [searchParams?.get('query'), defaultSearchValue]);

  const handleOnClickLocation = (location: SimpleLocationSearchDto) => {
    setSearchValue(createSearchValue(location.name));
    onClickLocation(location);
  };

  const handleOnChange = (option: SingleValue<CustomOption> | undefined) => {
    if (option?.value === CURRENT_LOCATION_VALUE) {
      setLoadingBrowserLocation(true);
      getDeviceLocalization();
      return;
    }

    const feature = resultsPlaces.find((r) => r.id === option?.value);

    if (feature) {
      return handleOnClickAddress(feature);
    }

    const location = resultsLocations.find((x) => x.slug === option?.value);
    if (location) {
      return handleOnClickLocation(location);
    }
  };

  const onCloseModalError = () => {
    setErrorLocalization(false);
    setSearchValue(null);
    setLoadingBrowserLocation(false);
  };

  return (
    <div className="w-full" data-testid="search-location-field">
      <SearchField
        className={className}
        placeholder={placeholder}
        onInputChange={onInputChangeSearch}
        onChange={handleOnChange}
        options={options}
        searchValue={searchValue}
        autoFocus={autoFocus}
        isScrollOnFocus={isScrollOnFocus}
        rightIndicator={rightIndicator}
        leftIndicator={leftIndicator}
        controlClassName={controlClassName}
      />
      {showErrorLocalization && (
        <LocalizationErrorModal onClose={onCloseModalError} />
      )}
    </div>
  );
}

function createSearchValue(inputValue: string | undefined) {
  if (!inputValue) return null;
  return { label: inputValue, value: '', description: '' };
}

function mapLocationsToOptions(
  locations: SimpleIndustriousLocationSearchDto[]
): CustomOption[] {
  return locations.map((location) => {
    const formattedAdr = getFormattedAddressByCountry(location);
    return {
      label: location.name,
      description: `${formattedAdr.addressLine1}, ${formattedAdr.addressLine2}`,
      value: location.slug,
    };
  });
}
